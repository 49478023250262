// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import Logo from 'src/@core/components/logo'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  return (

    <Box className='actions-left' sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {hidden ? (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IconButton color='inherit' onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
          <Box mt={'10px'}>
            <Link to='/' sx={{ textDecoration: 'none' }}>
              <Logo />
            </Link>
          </Box>
          <Box display='flex' flexDirection='row' alignItems='center' gap={3} justifyContent={'flex-end'}>
            <Button as={Link} size='small' to='https://customer.goparcel247.com/signup' sx={{ textDecoration: 'none', color: '#262626' }}>
              Sign Up
            </Button>
            <Button variant='contained' size='small' as={Link} to='https://customer.goparcel247.com/login' sx={{ textDecoration: 'none', }}>
              Login
            </Button>
          </Box>
        </Box>
      ) : null}

      {/* <Autocomplete hidden={hidden} settings={settings} /> */}
    </Box>
  )
}

export default AppBarContent
