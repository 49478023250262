// ** Icon imports
import HomeIcon from 'mdi-material-ui/Home'
const navigation = () => {
  return [
    {
      title: 'Home',
      // icon: HomeIcon,
      path: '/'
    },
    {
      title: 'Become A Driver',
      path: '/become-a-driver'
    },
    {
      title: 'About us',
      path: '/about-us'
    },
    {
      title: 'Career',
      path: '/career'
    },
    {
      title: 'Blog',
      path: '/blog'
    },
    {
      title: 'Contact',
      path: '/contact'
    },
    // {
    //   title: 'Login',
    //   path: 'https://customer.goparcel247.com/login'
    // },
    // {
    //   title: 'Sign Up',
    //   path: 'https://d2u7u7obksrdp.cloudfront.net/signup'
    // }
  ]
}

export default navigation
