import React, { useEffect, useState } from 'react'
import {
    Container,
    Box,
    Typography,
    Grid,
    Divider,
} from '@mui/material'
import { Link } from 'react-router-dom'
// import Logo from '../../assets/images/Logo.png'
import Mail from '../../assets/images/mail1.png'
import Twitter from '../../assets/images/twitter.png'
import Facebook from '../../assets/images/facebook.png'
import Linkin from '../../assets/images/linkin.png'
import Phone from '../../assets/images/phone1.png'
import Location from '../../assets/images/location1.png'
import Instagram from '../../assets/images/instagram.png'
import Logo from 'src/@core/components/logo'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import toast from 'react-hot-toast'

const Typo = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#FFFFFF',
    lineHeight: '33px',
}
const Footer = () => {
    const [loading, setLoading] = useState(false)
    const [footer, setFooter] = useState([])
    const getFooter = () => {
        setLoading(true)
        axiosInstance
            .get(`${ApiEndpoints.FOOTER.list}`, {})
            .then(response => {
                setFooter(response?.data?.data?.footerDetails || [])
            })
            .catch(error => {
                toast.error(error.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getFooter()
    }, [])
    if (loading || footer.length === 0) return null;

    const { linkedin, twitter, instagram, facebook } = footer[0];
    return (
        <>
            <Box sx={{ bgcolor: "#0C7362", pt: 15, pb: 5, padding: { xs: '20px', sm: '30px', md: '50px' } }}>
                <Container maxWidth='xl'  >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={5}  >
                            {/* <img src={Logo} alt="" style={{ width: '94px', height: '54px' }} /> */}
                            <Logo />
                            <Box display={"flex"} gap={2} mt={2} >
                                <img src={Mail} alt={Mail} style={{ width: '20px', height: '20px' }} />
                                <Typography variant='fm-p2' fontWeight={'400'} color={'#ffff'} lineHeight={'27px'}>support@goparcel247.com</Typography>
                            </Box>
                            <Box display={"flex"} gap={2} mt={2} >
                                <img src={Phone} alt={Phone} style={{ width: '20px', height: '20px' }} />
                                <Typography variant='fm-p2' fontWeight={'400'} color={'#ffff'} lineHeight={'27px'}>+234 904 636 3333</Typography>
                            </Box>
                            <Box display={"flex"} gap={2} mt={2} >
                                <img src={Location} alt={Location} style={{ width: '20px', height: '20px' }} />
                                <Typography variant='fm-p2' fontWeight={'400'} color={'#ffff'} lineHeight={'27px'} >Nigeria</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2} >
                            <Link to='/about-us' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>About Us</Typography></Link>
                            <Link to='/terms-and-condition' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>Refund and cancellation</Typography></Link>
                            <Link to='/faq' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>FAQS</Typography></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} >
                            {/* <Typography sx={{ ...Typo }}><Link to="/" style={{ color: "#fff", textDecoration: "none" }}>Become a courier for GoParcel</Link></Typography>
                            <Typography sx={{ ...Typo }}><Link to="/" style={{ color: "#fff", textDecoration: "none" }}>Deliver a courier</Link></Typography> */}
                            <Typography sx={{ ...Typo }}>Become a courier for GoParcel</Typography>
                            <Link to='https://customer.goparcel247.com/login' style={{ textDecoration: 'none' }}><Typography sx={{ ...Typo }}>Deliver a courier</Typography></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Typography sx={{ color: "#fff", fontSize: '18px', fontWeight: 600, lineHeight: '27px', marginBottom: '12px' }}>Social Profiles</Typography>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Link to={facebook} style={{ textDecoration: 'none' }} target='_blank'> <img src={Facebook} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to={twitter} style={{ textDecoration: 'none' }} target='_blank'><img src={Twitter} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to={linkedin} style={{ textDecoration: 'none' }} target='_blank'> <img src={Linkin} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to={instagram} target='_blank' style={{ textDecoration: 'none' }}> <img src={Instagram} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider color='#F1FDB7' sx={{ my: 6 }} />
                    <Grid container justifyContent={'space-between'}>
                        <Grid item xs={12} sm={6} md={9} >
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px', color: "#fff" }}>© {new Date().getFullYear()} Go Parcel. All rights reserved.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Box sx={{ display: "flex", gap: 7 }}>
                                <Link to='/privacy-policy' style={{ textDecoration: 'none' }}><Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px', color: "#fff" }}>Privacy Policy</Typography></Link>
                                <Link to='/terms-and-condition' style={{ textDecoration: 'none' }}><Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px', color: "#fff" }}>Terms and condition</Typography></Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Footer