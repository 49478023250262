// ** Icon imports

const navigation = () => {
  return [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'Become A Driver',
      path: '/become-a-driver'
    },
    {
      title: 'About us',
      path: '/about-us'
    },
    {
      title: 'Career',
      path: '/career'
    },
    {
      title: 'Blog',
      path: '/blog'
    },
    {
      title: 'Contact',
      path: '/contact'
    },


  ]
}

export default navigation
