export const API_BASE_URL = 'https://api.goparcel247.com'
//export const API_BASE_URL = "http://192.168.29.55:3000"
export const MEDIA_URL = 'https://d1hj3r7039erwq.cloudfront.net'

export const ApiEndpoints = {
  TESTIMONIAL: {
    list: `${API_BASE_URL}/api/v1/misc/testimonials`
  },
  PRIVACY_POLICY: {
    list: `${API_BASE_URL}/api/v1/misc/legalcontent/privacy_policy`,
  },
  TERMS: {
    list: `${API_BASE_URL}/api/v1/misc/legalcontent/terms_and_conditions`
  },
  CONTACTUS: {
    add: `${API_BASE_URL}/api/v1/misc/contact-us`
  },
  FAQ: {
    list: `${API_BASE_URL}/api/v1/misc/faqs`
  },
  CAREER: {
    list: `${API_BASE_URL}/api/v1/misc/careers`
  },
  BLOG: {
    list: `${API_BASE_URL}/api/v1/misc/blogs`,
    listbyid: id => `${API_BASE_URL}/api/v1/misc/blogs/${id}`
  },
  FOOTER: {
    list: `${API_BASE_URL}/api/v1/misc/footer`
  }
}