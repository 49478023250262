import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'

const HomePage = React.lazy(() => import('./pages/home'))
const BecomeDriverPage = React.lazy(() => import('./pages/becomeDriver'))
const AboutusPage = React.lazy(() => import('./pages/aboutus'))
const CareerPage = React.lazy(() => import('./pages/career'))
const ContactPage = React.lazy(() => import('./pages/contact'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsandconditionPage = React.lazy(() => import('./pages/Termsandcondition'))
const FaqDetailsPage = React.lazy(() => import('./pages/faq'))
const BlogPage = React.lazy(() => import('./pages/blog'))
const Blogdetails = React.lazy(() => import('./pages/blog/blogdetails'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))



function App() {
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route element={<BlankLayout><Outlet /></BlankLayout>}>
          <Route path='/401' element={<Page401 />} />
          <Route path='/404' element={<Page404 />} />
        </Route>

        <Route element={<UserLayout><Outlet /></UserLayout>}>
          <Route path='/' element={<HomePage />} />
          <Route path='/become-a-driver' element={<BecomeDriverPage />} />
          <Route path='/about-us' element={<AboutusPage />} />
          <Route path='/career' element={<CareerPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='/terms-and-condition' element={<TermsandconditionPage />} />
          <Route path='/faq' element={<FaqDetailsPage />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path="/blog/:id" element={<Blogdetails />} />
        </Route>

        <Route path='*' element={<Navigate to='/404' replace />} />

      </Routes>
    </Suspense>
  )
}

export default App
